export abstract class EventDispatcher {
    private _eventStorage:{[event:string]:Array<{caller:any,func:Function}>} = {};

    public on(event:string,caller:any,func:Function) {
        if(!this._eventStorage[event]) {
            this._eventStorage[event] = [];
        }
        let arr = this._eventStorage[event];
        arr.push({caller:caller,func:func});
    }

    public off(event:string,caller:any,func:Function) {
        let arr = this._eventStorage[event];
        if(!arr) return ;
        for(let i = 0;i < arr.length;i ++) {
            let item = arr[i];
            if(item.caller == caller && item.func == func) {
                arr.splice(i,1);
                return;
            }
        }
    }

    public emit(event:string,...args:Array<any>) {
        let arr = this._eventStorage[event];
        if(!arr) return ;
        for(let item of arr) {
            item.func.apply(item.caller,args);
        }
    }
}