









































import {Component, Prop, Ref, Vue, Emit} from 'vue-property-decorator';
import UserManager, {UserEvent} from "@/managers/user-manager";
import copy from "copy-to-clipboard";
import {message} from "ant-design-vue";
import {ApiApplyForBindQQ} from "@/request-apis/user/apply-for-bind-qq";

@Component
export default class UserModule extends Vue {
    private openAccountAuth:boolean = false;
    private accountAuthLoading:boolean = false;
    private accountAuthCode:string = "";
    private accountAuthError:string = "";

    private mounted() {
        UserManager.getInstance()['_userModuleComp'] = this;
        UserManager.getInstance().on(UserEvent.ON_LOGIN_STATE_REFRESH, this, this.onLoginStateRefresh);
        this.bindEvent();
    }

    private destroyed() {
        if(UserManager.getInstance()['_userModuleComp'] === this) {
            UserManager.getInstance()['_userModuleComp'] = undefined;
        }
        UserManager.getInstance().off(UserEvent.ON_LOGIN_STATE_REFRESH, this, this.onLoginStateRefresh);
    }

    private bindEvent() {
        this.$on('popupAuthQQ', () => {
            this.openAccountAuth = true;
            this.accountAuthCode = "";
            this.accountAuthError = "";
            new ApiApplyForBindQQ().run().then(response => {
                setTimeout(() => {
                    this.accountAuthCode = `#AUTH-ACCOUNT ${response.data.token}`;
                },1500)
            }).catch(e => {
                setTimeout(() => {
                    this.accountAuthCode = "ERROR";
                    this.accountAuthError = e.msg + ` (code:${e.code})`;
                },1500)
            });
        })
    }

    private onLoginStateRefresh() {
        if(this.openAccountAuth) {
            this.accountAuthLoading = false;
            if(UserManager.getInstance().getIsBindQQ()) {
                this.openAccountAuth = false;
            } else {
                this.$error({
                    title: "认证失败",
                    content: "您尚未发送代码给阿晴小助手，或发送的代码未接收成功，请再次尝试发送认证代码，或联系网站管理员。"
                });
            }
        }
    }

    private onAccountAuthOk() {
        this.accountAuthLoading = true;
        UserManager.getInstance().doLoadLogin();
    }

    private onCopyAuthCode() {
        if(copy(this.accountAuthCode)) {
            message.success("复制成功");
        } else {
            message.error("复制失败");
        }
    }
}
