import axios from "axios";
axios.defaults.withCredentials = true;
// axios.interceptors.request.use(config => {
//     if(process.env.NODE_ENV == 'development' && config.headers) {
//         config.headers["x-mimoe-login-token"] = 'B1pE0XcqDv2vFYFQf549bvg5MOXzOlfE2PnocjHPvy4UpTotxcNDYsS1Ids5KH6L';
//     }
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// })

export default class NetManager {
    // private static API_ROOT_URL:string = "//127.0.0.1:27482";
    private static API_ROOT_URL:string = "//apiv2.magecorn.com";

    public static get(urlPath:string, query?:{[key:string]:string}) {
        return NetManager.send(urlPath, "GET", query);
    }

    public static post(urlPath:string, query?:{[key:string]:string}, data?:{[key:string]:string}) {
        return NetManager.send(urlPath, "POST", query, data);
    }

    public static send(urlPath:string, method:"POST" | "GET", query?:{[key:string]:string}, data?:any, headers?:any) {
        let url = NetManager.API_ROOT_URL + urlPath;
        let queryStr = "";
        let csrfid = this.getCookieCsrfId();
        if(csrfid) {
            query = query || {};
            query['csrfid'] = csrfid;
        }
        if(process.env.NODE_ENV == 'development') {
            query = query || {};
            query['Mimoe_Login_Token'] = 'B1pE0XcqDv2vFYFQf549bvg5MOXzOlfE2PnocjHPvy4UpTotxcNDYsS1Ids5KH6L';
        }
        if(query) {
            for(let key in query) {
                queryStr += (queryStr.length == 0 ? "?" : "&");
                queryStr += key + "=" + query[key];
            }
            url = url + queryStr;
        }
        return axios({
            url: url,
            method: method,
            headers: headers,
            data: data
        });
    }

    private static getCookieCsrfId() {
        let cookies = document.cookie.split("; ");
        for (let i = 0; i < cookies.length; i++) {
            let s = cookies[i].split('=');
            if (s[0] == 'csrfid') return s[1];
        }
        return '';
    }
}